.HarasocialInteractionPage {
  height: 100%;
}

.InteractionItemComponent {
  width: 348px;
  display: flex;
  .verticle-line-wrapper {
    min-width: 10px;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 6px;
    .line {
      width: 1px;
      background-color: rgba(0, 0, 0, 0.06);
      height: 100%;
    }
    .circle {
      position: absolute;
      top: 6px;
      border: 2px solid var(--brand_primary);
      background-color: white;
      left: 0px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
  .content-wrapper {
    width: 338px;
    display: flex;
    flex-direction: column;
    .timestamp {
    }
    .content {
      &__product {
      }
    }
    .channel {
    }
  }
}
